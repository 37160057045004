<template>
    <div class="accordion-wrap" id="item" :class="{on:openAccordion}" @click="toggleAccordion">
        <div class="accordion-title-wrap">
<!--            <span class="category">{{category}}</span>-->
          <div class="title" :class="{open:openAccordion}">{{title}}</div>
          <div class="url-copy-btn flag-device" @click="urlCopyBtn" v-if="openAccordion">
            URL 복사
          </div>
        </div>
        <VueSlideToggle class="accordion-content-wrap" :open="openAccordion" :duration="200">
          <div class="content-wrap" v-on:click.stop>
            <div class="left">
              <img :src="imageSrc">
            </div>
            <div class="right">
              <p v-html="content"></p>
              <div class="url-copy-btn-mo flag-device" @click="urlCopyBtn">
                URL 복사
              </div>
            </div>
          </div>
        </VueSlideToggle>
    </div>
</template>

<script>
    import {VueSlideToggle} from 'vue-slide-toggle'
    export default {
        name: "Accordion",
        props: {
            no: {
                type: Number
            },
            category: {
                type: String
            },
            title: {
                type:String
            },
            imageSrc: {
                type:String
            },
            content: {
                type: String
            }
        },
        components: {
            VueSlideToggle
        },
        data() {
            return {
                openAccordion:false,
                isClickedUrlCopy:false
            }
        },
        mounted() {
          //URL Param을 통해 아코디언 Open 시키기 (링크 공유 기능 위함.)
          if(this.no === Number(this.$route.query.no)){
            this.openAccordion = true;
          }
        },
      updated() {
          //element 재랜더링 될 때 트리거, Open된 아코디언으로 세로 스크롤 이동 시키는 함수
        this.$nextTick(function () {
          const clientRect = this.$el.getBoundingClientRect(); // DomRect 구하기 (각종 좌표값이 들어있는 객체)
          const relativeTop = clientRect.top; // Viewport의 시작지점을 기준으로한 상대좌표 Y 값.
          const scrolledTopLength = window.pageYOffset; // 스크롤된 길이
          const absoluteTop = scrolledTopLength + relativeTop; // 절대좌표
          window.scrollTo({ left: 0, top: absoluteTop-95, behavior: "smooth" });
        })
      },
      methods: {
            urlCopyBtn() {
              this.isClickedUrlCopy = true;
              var url = '';
              var textarea = document.createElement("textarea");
              document.body.appendChild(textarea);
              url = location.protocol + "//" + location.host + "/cs/faq?no=" + this.no;
              textarea.value = url;
              textarea.select();
              document.execCommand("copy");
              document.body.removeChild(textarea);
              alert('링크가 복사 되었습니다.')
            },
            toggleAccordion() {
              if(!this.isClickedUrlCopy){
                this.openAccordion = !this.openAccordion
              }
              this.isClickedUrlCopy = false;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .open {
        white-space: pre-wrap !important;
        word-break: keep-all;
        /*line-height: 20px !important;*/
    }
  .content-wrap {
    display: flex;
    @media (max-width: 768px){
      flex-direction: column;
    }
    .left {
      width: 200px;
      margin-right: 50px;
      @media (max-width: 768px){
        width: 100%;
        text-align: center;
      }
      img {
        width: 100%;
        @media (max-width: 768px){
          display: inline-block;
          width: 200px;
        }
      }
    }
    .right {
      width: calc(100% - 200px);
      font-size: 15px;
      text-align: left;
      line-height: 28px;
      padding-top:12px;
      @media (max-width: 768px){
        width: 100% !important;
      }
      img {
        width: 100%;
        margin-bottom:5px;
      }
    }
  }

  .url-copy-btn.flag-device {
    visibility: visible;
    @media (max-width: 768px){
      visibility: hidden !important;
    }
  }
  .url-copy-btn-mo.flag-device {
    visibility: hidden !important;
    @media (max-width: 768px){
      visibility: visible !important;
    }
  }

</style>
